import React, { Component } from 'react';
import './FeaturedStreamer.scss';
import FollowButton from '../FollowButton/FollowButton';



class FeaturedStreamer extends Component
{
    constructor() {
        super();
        this.state = {
            isLoaded: false,
            streamer: {}
        };

        this.url = 'https://www.lowsodiumcoalition.com/shaker/wp-json';
        this.endpoint = '/saltines/featured';
    }

    componentDidMount() {
        fetch(this.url + this.endpoint).then(res => res.json()).then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    streamer: result.data
                });
            },
            (error) => {
                this.setState({
                    isLoaded:true,
                    error
                });
            }
        )
    }

    render() {
        const { error, isLoaded, streamer } = this.state;

        if(error) {
            return (
                <div className="error notice">Error: { error.message }</div>
            );
        } else if(!isLoaded) {
            return (
                <div className="loading">Loading...</div>
            );
        } else {
            if(!streamer.featured) {
                return null;
            } else {
                return(
                    <div className="featured-streamer-container">
                        <h1 className="fs-title">Featured <strong>Streamer</strong></h1>
                        <div className="featured-streamer">
                            <div className="featured-streamer-avatar">
                                <img src={ streamer.featured.avatar } alt={ streamer.featured.name } />
                            </div>
                            <div className="featured-streamer-meta">
                                <h2 className="fs-name">{ streamer.featured.name }</h2>
                                <p>{ streamer.featured.bio }</p>

                                <div className="streamer-follow">
                                    <ul>
                                            <li className="twitch-btn">
                                                <FollowButton link={ streamer.featured.twitch } icon="twitch" label="Follow &amp; Watch" />
                                            </li>
                                    {
                                        streamer.featured.social.map((so)=> {
                                            return (
                                                <li key={ so.type }>
                                                    <FollowButton link={ so.link } icon={ so.type } />
                                                </li>
                                            );
                                        })
                                    }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}

export default FeaturedStreamer;