import React from 'react';
import logo from '../../_assets/img/low-sodium-coalition-logo.svg';
import './Sidebar.scss';

function Sidebar() {
    return (
        <aside className="lsc-sidebar">
            <div className="logo-container">
                <img src={ logo } alt="Low Sodium Coalition" />
                <h1>
                    <strong>Low Sodium</strong>
                    <span>Coalition</span>
                </h1>
            </div>
            <p>Low sodium coalition is a group of Twitch Friends commited to creating a safe, relaxing environment for all of our viewers. With very low levels of sodium on a 2,000 calorie diet.</p>
            
        </aside>
    )
}

export default Sidebar;