import React, { Component } from 'react';
import FollowButton from '../FollowButton/FollowButton';
import FeaturedStreamer from '../FeaturedStreamer/FeaturedStreamer';
import './Main.scss';

class Main extends Component
{
    constructor() {
        super();
        this.state = {
            error: null,
            isLoaded: false,
            streamers: []
        }

        this.url = 'https://www.lowsodiumcoalition.com/shaker/wp-json';
        this.endpoint = '/saltines/members';

    }

    componentDidMount() {
        
        fetch(this.url + this.endpoint).then(res => res.json()).then(
            (result) => {
                console.log(result);
                this.setState({
                    isLoaded: true,
                    streamers: result.data
                });
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        );
    }

    render() {
        const { error, isLoaded, streamers } = this.state;

        if(error) {
            return (
                <div className="error notice">Error: { error.message }</div>
            );
        } else if (!isLoaded) {
            return (
                <div className="warning notice">Loading...</div>
            );
        } else {
            return (
                <div className="container">
                    <FeaturedStreamer />
                    <h2 className="section-title">Roster</h2>
                    <div className="streamers-container">
                        { 
                            streamers.map(streamer => (
                                    <div className="streamer" key="LSC_{ streamer.ID }">
                                        <div className="streamer-avatar-container">
                                            <a href={ streamer.twitch } target="_blank" className="cover" rel="noopener noreferrer"> </a>
                                            <img src={ streamer.avatar } className="streamer-avatar" alt={ streamer.name } />
                                            <div class="streamer-bio">
                                                <p>{ streamer.bio }</p>
                                            </div>
                                        </div>
                                        <h2>{ streamer.name }</h2>
                                        <div className="streamer-follow">
                                            <ul>
                                                <li className="twitch-btn"><FollowButton link={ streamer.twitch } icon="twitch" label="Follow" /></li>
                                                { 
                                                    streamer.social.map( function(so) {
                                                        return (
                                                            <li key={ so.type }><FollowButton link={ so.link } icon={ so.type } /></li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
            )
        }
    }
}


export default Main;