import React from 'react';

//Components
import Sidebar from './components/Sidebar/Sidebar';
import Main from './components/Main/Main';

// SCSS
import './App.scss';

function App() {
  return (
    <main className="lsc-website">
      <Sidebar />
      <Main />
    </main>
  );
}

export default App;
