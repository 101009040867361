import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitch, faFacebook, faTwitter, faInstagram, faDiscord } from '@fortawesome/free-brands-svg-icons'

import './FollowButton.scss';


class FollowButton extends Component
{
    constructor(props){
        super(props);

        this.link = props.link;
        this.icon = props.icon;
        this.label = (props.label) ? props.label : '';

        this.state = {
            faIcon: ''
        };

    }

    componentDidMount() {
        
        switch(this.icon) {
            case 'twitch':
                this.setState({
                    faIcon: faTwitch
                });
                break;
            case 'facebook':
                this.setState({
                    faIcon: faFacebook
                });
                break;
            case 'twitter':
                this.setState({
                    faIcon: faTwitter
                });
                break;
            case 'instagram':
                this.setState({
                    faIcon: faInstagram
                });
                break;
            case 'discord':
                this.setState({
                    faIcon: faDiscord
                });
                break;
            default:
                this.setState({
                    faIcon: ''
                });
        }

        return this.faIcon
        
    }

    render() {
        const { faIcon } = this.state;

        let alt = (this.label === '') ? ' alt' : '';

        console.log(faIcon);



        return (
            <a href={ this.link } className={`follow-btn${ alt }`} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={ faIcon } />
                { this.label }
            </a>
        )
    }


}

export default FollowButton;